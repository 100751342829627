import React from "react"
import { Form } from "skillstrainer-resource-library"
import IndoRussiaFormBuilder from "../formBuilder/indo-russia-form-builder"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"

export default function IndoRussiaRegistrationForm() {
  const handleSubmit = async register => {
    console.log("register===", register)
    const payload = {
      data: {
        entity: {
          type: "user-interest",
          eventId: 1,
        },
        operation: "insert",
        coupon_code: register.coupon,
        data: {
          form_type: "indo-russia",
          full_name: register.full_name,
          designation: register.designation,
          email: register.email,
          confirmation: register.confirmation,
          query: register.query,
          updates: register.updates,
          registration_type: register.registration_type,
          company_name: "not required",
          phone_number: "1234567890",
          country: "not required",
        },
      },
    }

    try {
      const result = await axios.post(
        `${process.env.URL}/api/user-interests`,
        payload
      )

      if (result.status === 200) {
        return toast.success("Success", {
          position: toast.POSITION.TOP_CENTER,
        })
      } else {
        toast.error("Something gone wrong!", {
          position: toast.POSITION.TOP_CENTER,
        })
      }
    } catch (err) {
      console.log("I am error", err)
      return toast.error("Something gone wrong!", {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  }
  return (
    <>
      <div className="mt-5 pt-3 px-5">
        <h1 className="heading mb-3">
          INDIA-RUSSIA ROUND TABLE REGISTRATION FORM
        </h1>
        <ToastContainer />

        <Form
          formBuilder={IndoRussiaFormBuilder}
          className=" ml-6 mt-2 grid grid-cols-2 gap-x-2"
          submitButton={{
            text: "Save",
            className: "btn learn-more-btn",
          }}
          onSubmit={handleSubmit}
        />
      </div>

      <div className="mt-5 pt-5"></div>
    </>
  )
}
